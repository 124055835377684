import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';

import { Store } from '@ngrx/store';
import { Paginate } from '../../../models/pagination.model';
import { TenantEdgeConsumption } from '../models/tenant-edge-consumption.model';
import { TenantUsageGraphData } from '../models/tenant-usage-graph-data.model';
import { TenantUsageWorkingGraphData } from '../models/tenant-usage-working-graph-data.model';
import { TenantUsage } from '../models/tenant-usage.model';
import { DashboardService } from '../service/dashboard.service';
import { dashboardActions } from './dashboard.actions';

@Injectable()
export class DashboardEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly dashboardService = inject(DashboardService);

  getDepartmentsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.getDepartmentsCount),
      switchMap(() =>
        this.dashboardService.getDepartmentsCount().pipe(
          map((count: number) =>
            dashboardActions.getDepartmentsCountSuccess({
              count,
            })
          )
        )
      )
    )
  );

  getOperatorsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.getOperatorsCount),
      switchMap(({ tenant }) =>
        this.dashboardService.getOperatorsCount(tenant).pipe(
          map((count: number) =>
            dashboardActions.getOperatorsCountSuccess({
              count,
            })
          )
        )
      )
    )
  );

  getBundlesCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.getBundlesCount),
      switchMap(() =>
        this.dashboardService.getBundlesCount().pipe(
          map((count: number) =>
            dashboardActions.getBundlesCountSuccess({
              count,
            })
          )
        )
      )
    )
  );

  getTenantUsage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.getTenantUsage),
      switchMap(({ tenant }) =>
        this.dashboardService.getTenantUsage(tenant).pipe(
          map((tenantUsage: TenantUsage) =>
            dashboardActions.getTenantUsageSuccess({
              tenantUsage,
            })
          )
        )
      )
    )
  );

  getTenantUsageInteractions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.getTenantUsageInteractions),
      switchMap(({ range, tenant }) =>
        this.dashboardService.getTenantUsageInteractions(range, tenant).pipe(
          map((tenantUsageInteractions: TenantUsageGraphData) =>
            dashboardActions.getTenantUsageInteractionsSuccess({
              tenantUsageInteractions,
            })
          )
        )
      )
    )
  );

  getTenantUsageDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.getTenantUsageDocuments),
      switchMap(({ range, tenant, totalOnly }) =>
        this.dashboardService.getTenantUsageDocuments(range, tenant, totalOnly).pipe(
          map((tenantUsageDocuments: TenantUsageGraphData) =>
            dashboardActions.getTenantUsageDocumentsSuccess({
              tenantUsageDocuments,
            })
          )
        )
      )
    )
  );

  getTenantUsagePages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.getTenantUsagePages),
      switchMap(({ range, tenant, totalOnly }) =>
        this.dashboardService.getTenantUsagePages(range, tenant, totalOnly).pipe(
          map((tenantUsagePages: TenantUsageGraphData) =>
            dashboardActions.getTenantUsagePagesSuccess({
              tenantUsagePages,
            })
          )
        )
      )
    )
  );

  getTenantUsageSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.getTenantUsageSummaries),
      switchMap(({ range, tenant, totalOnly }) =>
        this.dashboardService.getTenantUsageSummaries(range, tenant, totalOnly).pipe(
          map((tenantUsageSummaries: TenantUsageGraphData) =>
            dashboardActions.getTenantUsageSummariesSuccess({
              tenantUsageSummaries,
            })
          )
        )
      )
    )
  );

  getTenantUsageOperators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.getTenantUsageOperators),
      switchMap(({ tenant, role }) =>
        this.dashboardService.getTenantUsageOperators(tenant, role).pipe(
          map((tenantUsageOperators: number) =>
            dashboardActions.getTenantUsageOperatorsSuccess({
              tenantUsageOperators,
            })
          )
        )
      )
    )
  );

  getTenantUsageBundles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.getTenantUsageBundles),
      switchMap(({ tenant }) =>
        this.dashboardService.getTenantUsageBundles(tenant).pipe(
          map((tenantUsageBundles: number) =>
            dashboardActions.getTenantUsageBundlesSuccess({
              tenantUsageBundles,
            })
          )
        )
      )
    )
  );

  getTenantEdges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.getTenantEdges),
      switchMap(({ page, pageSize }) =>
        this.dashboardService.getTenantEdges(page, pageSize).pipe(
          map((tenantEdges: Paginate<TenantEdgeConsumption>) =>
            dashboardActions.getTenantEdgesSuccess({
              tenantEdges,
            })
          )
        )
      )
    )
  );

  getTenantUsageRepositories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.getTenantUsageRepositories),
      switchMap(({ tenant }) =>
        this.dashboardService.getTenantUsageRepositories(tenant).pipe(
          map((tenantUsageRepositories: number) =>
            dashboardActions.getTenantUsageRepositoriesSuccess({
              tenantUsageRepositories,
            })
          )
        )
      )
    )
  );

  getTenantUsageTopWorkingOperators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.getTenantUsageTopWorkingOperators),
      switchMap(({ range, tenant, pageSize }) =>
        this.dashboardService.getTenantUsageTopWorkingOperators(range, tenant, pageSize).pipe(
          map((tenantUsageTopWorkingOperators: TenantUsageWorkingGraphData) =>
            dashboardActions.getTenantUsageTopWorkingOperatorsSuccess({
              tenantUsageTopWorkingOperators,
            })
          )
        )
      )
    )
  );

  getTenantUsageTopWorkingTenants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.getTenantUsageTopWorkingTenants),
      switchMap(({ range, tenant }) =>
        this.dashboardService.getTenantUsageTopWorkingTenants(range, tenant).pipe(
          map((tenantUsageTopWorkingTenants: TenantUsageWorkingGraphData) =>
            dashboardActions.getTenantUsageTopWorkingTenantsSuccess({
              tenantUsageTopWorkingTenants,
            })
          )
        )
      )
    )
  );
}

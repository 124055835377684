import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SKIP_GLOBAL_LOADER } from '../../../../core/loader/loader.interceptor';
import { Paginate } from '../../../models/pagination.model';
import { ResponseDto } from '../../../models/response-dto.model';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../utils/pagination.utils';
import { Audit } from '../models/audit.model';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  private readonly http = inject(HttpClient);

  getAudits(page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE) {
    const params = new HttpParams()
      .set('page', page)
      .set('pageSize', pageSize)
      .set('actionCategory', 'RECENT_ACTIVITIES');

    return this.http
      .get<ResponseDto<Paginate<Audit>>>(`/audits`, {
        params,
        context: new HttpContext().set(SKIP_GLOBAL_LOADER, true),
      })
      .pipe(map((response) => response.data));
  }

  getAuditsByUserId(
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    id?: string,
    tenant?: string
  ): Observable<Paginate<Audit>> {
    let params = new HttpParams()
      .set('page', page)
      .set('pageSize', pageSize)
      .set('actionCategory', 'RECENT_ACTIVITIES');

    if (id) {
      params = params.set('userId', id);
    }

    if (tenant) {
      params = params.set('tenant', tenant);
    }

    return this.http
      .get<ResponseDto<Paginate<Audit>>>(`/management-audits`, {
        params,
        context: new HttpContext().set(SKIP_GLOBAL_LOADER, true),
      })
      .pipe(map((response) => response.data));
  }
}

// const MOCK = {
//   status: 'OK',
//   data: {
//     items: [
//       {
//         actionCategory: 'RECENT_ACTIVITIES',
//         actionDetail: 'CREATE_BUNDLE',
//         payload: {
//           bundleId: '66fd1599f0acc2c22d0bb9b2',
//           bundleName: 'SIRL3 (GARA)',
//           departmentId: '66fcfea1c7f18682f02af6fb',
//         },
//         resultStatus: 'OK',
//         userId: '66fa45d2c7f18682f02ae73e',
//         ipAddress: '79.8.227.111',
//         email: 'raghav.singhal+admindev@ennova-research.com',
//         timestamp: '2024-10-02T09:42:49.475Z',
//         tenant: 'vega',
//         fullName: 'raghav singhal',
//       },
//       {
//         actionCategory: 'RECENT_ACTIVITIES',
//         actionDetail: 'CREATE_BUNDLE',
//         payload: {
//           bundleId: '66fd0630f0acc2c22d0bb37b',
//           bundleName: 'AQ_81_24 (CONSIP)',
//           departmentId: '66fcfea1c7f18682f02af6fb',
//         },
//         resultStatus: 'OK',
//         userId: '66fa45d2c7f18682f02ae73e',
//         ipAddress: '79.8.227.111',
//         email: 'raghav.singhal+admindev@ennova-research.com',
//         timestamp: '2024-10-02T08:37:04.666Z',
//         tenant: 'vega',
//         fullName: 'raghav singhal',
//       },
//       {
//         actionCategory: 'RECENT_ACTIVITIES',
//         actionDetail: 'CREATE_QA_CHAT',
//         payload: {
//           bundleId: ['66fa9b50f0acc2c22d0b67cb'],
//           bundleName: 'SW_Nervesa_Affitto_Lavaggio',
//         },
//         resultStatus: 'OK',
//         userId: '66d9aef80ea5a5e2407e90bb',
//         ipAddress: '79.8.227.111',
//         email: 'pietro.montino+admin@ennova-research.com',
//         timestamp: '2024-10-02T08:14:30.268Z',
//         tenant: 'vega',
//         fullName: 'Pietro Montino',
//       },
//       {
//         actionCategory: 'RECENT_ACTIVITIES',
//         actionDetail: 'CREATE_BUNDLE',
//         payload: {
//           bundleId: '66fcff91f0acc2c22d0bad82',
//           bundleName: 'AQ 1499_22 (CONSIP)',
//           departmentId: '66fcfea1c7f18682f02af6fb',
//         },
//         resultStatus: 'OK',
//         userId: '66fa45d2c7f18682f02ae73e',
//         ipAddress: '79.8.227.111',
//         email: 'raghav.singhal+admindev@ennova-research.com',
//         timestamp: '2024-10-02T08:08:49.832Z',
//         tenant: 'vega',
//         fullName: 'raghav singhal',
//       },
//       {
//         actionCategory: 'RECENT_ACTIVITIES',
//         actionDetail: 'CREATE_QA_CHAT',
//         payload: {
//           bundleId: ['66fa9b50f0acc2c22d0b67cb'],
//           bundleName: 'SW_Nervesa_Affitto_Lavaggio',
//         },
//         resultStatus: 'OK',
//         userId: '66d9aef80ea5a5e2407e90bb',
//         ipAddress: '79.8.227.111',
//         email: 'pietro.montino+admin@ennova-research.com',
//         timestamp: '2024-10-01T16:20:09.201Z',
//         tenant: 'vega',
//         fullName: 'Pietro Montino',
//       },
//       {
//         actionCategory: 'RECENT_ACTIVITIES',
//         actionDetail: 'CREATE_QA_CHAT',
//         payload: {
//           bundleId: ['66fa9b50f0acc2c22d0b67cb'],
//           bundleName: 'SW_Nervesa_Affitto_Lavaggio',
//         },
//         resultStatus: 'OK',
//         userId: '66d9aef80ea5a5e2407e90bb',
//         ipAddress: '79.8.227.111',
//         email: 'pietro.montino+admin@ennova-research.com',
//         timestamp: '2024-10-01T11:04:13.116Z',
//         tenant: 'vega',
//         fullName: 'Pietro Montino',
//       },
//     ],
//     pagination: {
//       currentPage: 1,
//       nextPage: null,
//       prevPage: null,
//       totalPages: 1,
//       totalRecords: 6,
//     },
//   },
// } as ResponseDto<Paginate<Audit>>;

<div class="items-top max-h-80vh">
  <!-- Main modal -->
  <div class="relative p-8 mx-1 sm:p-6 bg-white dark:bg-dark-purple-900 rounded-lg">
    <!-- Form  -->
    <div class="flex items-center mb-6 pb-4 w-full border-b">
      <h5 class="text-lg font-miriam tracking-tight text-light-purple-700 dark:text-white">
        {{ 'settings.title' | transloco }}
      </h5>
      <button
        type="button"
        class="text-gray-400 bg-transparent hover:bg-dark-purple-100 hover:text-gray-900 rounded-full text-sm w-10 h-10 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white transition-colors ease-in-out duration-300"
        (click)="close()"
      >
        <span class="text-dark-purple-500 text-xl mdi mdi-close"></span>
        <span class="sr-only">Close modal</span>
      </button>
    </div>

    <div class="md:flex" *ngIf="user() as user">
      <!-- Tabs -->
      <ul class="flex flex-col space-y-4 min-w-64 font-medium text-gray-500 dark:text-gray-400 md:mb-0">
        <li>
          <a
            class="inline-flex items-center tab-link cursor-pointer"
            data-tab="settings-account"
            (click)="activeIndex.set(0)"
            [ngClass]="{ 'font-bold text-coral-500': activeIndex() === 0 }"
          >
            Account
          </a>
        </li>
        <li>
          <a
            class="inline-flex items-center tab-link cursor-pointer"
            data-tab="settings-system"
            (click)="activeIndex.set(1)"
            [ngClass]="{ 'font-bold text-coral-500': activeIndex() === 1 }"
          >
            {{ 'settings.system-e-accessibility.title' | transloco }}
          </a>
        </li>

        <!-- <li>
          <a class="inline-flex items-center tab-link" data-tab="settings-notification"> Notification </a>
        </li>
        <li>
          <a class="inline-flex items-center tab-link" data-tab="settings-privacy"> Privacy </a>
        </li>
        <li>
          <a class="inline-flex items-center tab-link" data-tab="settings-support"> Support & Feedback </a>
        </li>
        <li>
          <a class="inline-flex items-center tab-link" data-tab="settings-misc"> Miscellaneous </a>
        </li> -->

        <li>
          <a class="cursor-pointer inline-flex items-center border-t pt-3" (click)="logout()">
            <i class="mdi mdi-logout me-1"></i>{{ 'settings.sign-out' | transloco }}
          </a>
        </li>
      </ul>
      <!-- Tab Content
          ---------------------------------------------------------- /-->
      <!-- Account Settings-->
      <div
        class="tab-content p-6 mb-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full overflow-y-auto max-h-500"
        id="settings-account"
        *ngIf="activeIndex() === 0"
      >
        <div class="mb-8">
          <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-2">
            {{ 'settings.personal-information.title' | transloco }}
          </h3>
          <div class="flex py-4 px-3 items-center border-b">
            <p class="w-1/2 text-dark-purple-500 font-semibold">Avatar</p>
            <div class="flex w-1/2 justify-end items-end ml-auto">
              <span
                class="bg-violet-500 rounded-full w-12 h-12 p-1.5 font-bold text-xl text-white flex items-center justify-center ring-2 ring-gray-300 dark:ring-gray-500"
              >
                {{ user.avatar }}
              </span>
            </div>
          </div>
          <div class="flex py-4 px-3 items-center border-b">
            <p class="w-1/2 text-dark-purple-500 font-semibold">
              {{ 'common.full-name' | transloco }}
            </p>
            <div class="flex w-1/2 justify-end items-end ml-auto">
              <a class="font-bold">{{ user.fullName }}</a>
            </div>
          </div>
          <div class="flex py-4 px-3 items-center">
            <p class="w-1/2 text-dark-purple-500 font-semibold">Email</p>
            <div class="flex w-1/2 justify-end items-end ml-auto">
              <a class="font-bold">{{ user.email }}</a>
            </div>
          </div>
        </div>
        <div class="">
          <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-2">
            {{ 'settings.account-security.title' | transloco }}
          </h3>
          <div class="flex py-4 px-3 items-center">
            <p class="w-1/2 text-dark-purple-500 font-semibold">Password</p>
            <div class="flex w-1/2 justify-end items-end ml-auto">
              <button
                type="button"
                class="text-white bg-dark-purple-500 hover:bg-dark-purple-700 transition-colors ease-in-out duration-150 focus:ring-3 focus:outline-none focus:ring-dark-purple-200 dark:focus:ring-dark-purple-800 font-medium rounded-full text-sm tracking-wide px-4 py-2 text-center"
                (click)="sendUpdatePasswordLink()"
              >
                {{ 'common.reset-password' | transloco }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Notification-->
      <div
        class="tab-content hidden p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full overflow-y-auto max-h-500"
        id="settings-notification"
      >
        <div class="mb-8">
          <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-2">Email Notifications</h3>
          <div class="flex py-4 px-3 items-center border-b">
            <p class="w-1/2 text-dark-purple-500 font-semibold">
              New Interactions
              <span class="block text-sm font-medium text-dark-purple-400"
                >Receive notifications when new interactions are initiated or when there are updates to existing
                interactions.</span
              >
            </p>
            <div class="flex w-1/2 justify-end items-end ml-auto">
              <label class="inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" />
                <div
                  class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-coral-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-coral-500"
                ></div>
              </label>
            </div>
          </div>
          <div class="flex py-4 px-3 items-center border-b">
            <p class="w-1/2 text-dark-purple-500 font-semibold">
              System Updates
              <span class="block text-sm font-medium text-dark-purple-400"
                >Get notified about important system updates, maintenance schedules, and new feature releases.</span
              >
            </p>
            <div class="flex w-1/2 justify-end items-end ml-auto">
              <label class="inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" checked />
                <div
                  class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-coral-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-coral-500"
                ></div>
              </label>
            </div>
          </div>
          <div class="flex py-4 px-3 items-center border-b">
            <p class="w-1/2 text-dark-purple-500 font-semibold">
              Document Uploads
              <span class="block text-sm font-medium text-dark-purple-400"
                >Be alerted when new documents are uploaded to the repositories you have access to.</span
              >
            </p>
            <div class="flex w-1/2 justify-end items-end ml-auto">
              <label class="inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" checked />
                <div
                  class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-coral-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-coral-500"
                ></div>
              </label>
            </div>
          </div>
          <div class="flex py-4 px-3 items-center border-b">
            <p class="w-1/2 text-dark-purple-500 font-semibold">
              Bundle Changes
              <span class="block text-sm font-medium text-dark-purple-400"
                >Notifications for any changes or updates made to document bundles.</span
              >
            </p>
            <div class="flex w-1/2 justify-end items-end ml-auto">
              <label class="inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" />
                <div
                  class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-coral-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-coral-500"
                ></div>
              </label>
            </div>
          </div>
        </div>
        <div class="mb-8">
          <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-2">SMS Notifications</h3>
          <div class="flex py-4 px-3 items-center border-b">
            <p class="w-1/2 text-dark-purple-500 font-semibold">
              System Updates
              <span class="block text-sm font-medium text-dark-purple-400"
                >Get notified about important system updates, maintenance schedules, and new feature releases.</span
              >
            </p>
            <div class="flex w-1/2 justify-end items-end ml-auto">
              <label class="inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" />
                <div
                  class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-coral-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-coral-500"
                ></div>
              </label>
            </div>
          </div>
        </div>

        <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-2">In-App Notifications (TBD)</h3>
      </div>

      <!-- System & Accessibility -->
      <div
        class="tab-content p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full overflow-y-auto max-h-500"
        id="settings-system"
        *ngIf="activeIndex() === 1"
      >
        <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-2">
          {{ 'settings.system-e-accessibility.title' | transloco }}
        </h3>
        <div class="flex py-4 px-3 items-center border-b">
          <p class="w-1/2 text-dark-purple-500 font-semibold">
            {{ 'settings.system-e-accessibility.appearance.title' | transloco }}
            <span class="block text-sm font-medium text-dark-purple-400">{{
              'settings.system-e-accessibility.appearance.subtitle' | transloco
            }}</span>
          </p>
          <div class="flex w-1/2 justify-end items-end ml-auto">
            <div class="flex items-center relative">
              <button
                id="dropdownProject"
                class="inline-flex items-center font-semibold text-center hover:underline focus:outline-none dark:bg-gray-900 dark:hover:bg-gray-800 dark:text-white"
                disabled
              >
                Light<span class="mdi mdi-chevron-down text-lg text-dark-purple-500 ml-1"></span>
              </button>
              <div
                id="theme-dropdown"
                class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-32 dark:bg-gray-700"
              >
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
                  <li>
                    <a
                      href="#"
                      class="block px-4 py-2 rounded-lg mx-2 hover:bg-dark-purple-50 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Light</a
                    >
                  </li>
                  <li>
                    <a
                      href="#"
                      class="block px-4 py-2 rounded-lg mx-2 hover:bg-dark-purple-50 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Dark</a
                    >
                  </li>
                  <li>
                    <a
                      href="#"
                      class="block px-4 py-2 rounded-lg mx-2 hover:bg-dark-purple-50 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      System</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="flex py-4 px-3 items-center border-b">
          <p class="w-1/2 text-dark-purple-500 font-semibold">
            {{ 'settings.system-e-accessibility.language.title' | transloco }}
            <span class="block text-sm font-medium text-dark-purple-400">{{
              'settings.system-e-accessibility.language.subtitle' | transloco
            }}</span>
          </p>
          <div class="flex w-1/2 justify-end items-end ml-auto">
            <div class="flex items-center relative">
              <button
                id="dropdownProject"
                [tp]="languageSelector"
                tpPlacement="bottom"
                tpVariation="menu"
                class="inline-flex items-center font-semibold text-center text-coral-600 hover:text-coral-700 hover:underline focus:outline-none dark:bg-gray-900 dark:hover:bg-gray-800 dark:text-white"
              >
                {{ language() }}<span class="mdi mdi-chevron-down text-lg text-dark-purple-500 ml-1"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex py-4 px-3 items-center">
          <p class="w-1/2 text-dark-purple-500 font-semibold">
            {{ 'settings.system-e-accessibility.sessions.title' | transloco }}
            <span class="block text-sm font-medium text-dark-purple-400">{{
              'settings.system-e-accessibility.sessions.subtitle' | transloco
            }}</span>
          </p>
          <div class="flex w-1/2 justify-end items-end ml-auto">
            <button
              class="text-sm font-medium px-4 py-2 rounded-full text-dark-purple-500 bg-transparent border border-dark-purple-200 hover:border-dark-purple-300 hover:bg-dark-purple-100 transition-all ease-in-out duration-300 cursor-not-allowed opacity-85"
              disabled
            >
              <i class="mdi mdi-logout me-1"></i>{{ 'settings.system-e-accessibility.sessions.button' | transloco }}
            </button>
          </div>
        </div>
      </div>

      <!-- Privacy -->
      <div
        class="tab-content hidden p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full overflow-y-auto max-h-500"
        id="settings-privacy"
      >
        <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-2">Privacy</h3>
        <div class="flex py-4 px-3 items-center border-b">
          <p class="w-1/2 text-dark-purple-500 font-semibold">
            Data Sharing
            <span class="block text-sm font-medium text-dark-purple-400"
              >Manage consent for sharing data with third-party services</span
            >
          </p>
          <div class="flex w-1/2 justify-end items-end ml-auto">
            <label class="inline-flex items-center cursor-pointer">
              <input type="checkbox" value="" class="sr-only peer" />
              <div
                class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-coral-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-coral-500"
              ></div>
            </label>
          </div>
        </div>
      </div>

      <!-- Support & Feedback -->
      <div
        class="tab-content hidden p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full overflow-y-auto max-h-500"
        id="settings-support"
      >
        <div class="mb-8">
          <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-2">Contact Support</h3>
          <div class="flex py-4 px-3 items-center border-b">
            <p class="w-full text-dark-purple-500 font-semibold">
              If you need any assistance, you can contact your administrator at
              <a
                href="mailto:admin@example.com"
                class="text-coral-500 hover:text-coral-600 transition-colors ease-in-out duration-150"
                >adminATexample.com</a
              >
            </p>
          </div>
        </div>
        <div>
          <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-2">Submit Feedback</h3>
          <div class="flex flex-col py-4 px-3 items-center">
            <p class="w-full text-dark-purple-500 font-semibold">
              Provide feedback or report issues using the detailed form below.
            </p>

            <form class="w-full space-y-5" action="#">
              <div class="mt-3">
                <label for="subject" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Subject</label
                >
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder="Provide a brief subject for your feedback"
                  class="border border-gray-300 text-gray-900 rounded-lg focus:ring-coral-300 focus:border-coral-300 block w-full px-5 py-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-coral-700 dark:focus:border-coral-700"
                  required=""
                />
              </div>
              <div class="mt-3">
                <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Description</label
                >
                <textarea
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Describe your feedback in detail"
                  class="border border-gray-300 text-gray-900 rounded-lg focus:ring-coral-300 focus:border-coral-300 block w-full px-5 py-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-coral-700 dark:focus:border-coral-700"
                  required=""
                ></textarea>
              </div>
              <div class="mt-3">
                <label for="attachment" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Attachment</label
                >

                <div class="flex items-center justify-center w-full">
                  <label
                    for="dropzone-file"
                    class="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span class="font-semibold">Click to upload</span> or drag and drop
                      </p>
                      <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, MP4 or GIF (MAX. 10 MB)</p>
                    </div>
                    <input id="dropzone-file" type="file" class="hidden" />
                  </label>
                </div>
              </div>

              <div class="mt-3">
                <button
                  type="button"
                  class="text-white bg-dark-purple-500 hover:bg-dark-purple-700 transition-colors ease-in-out duration-150 focus:ring-3 focus:outline-none focus:ring-dark-purple-200 dark:focus:ring-dark-purple-800 font-medium rounded-full text-sm tracking-wide px-4 py-2 text-center"
                >
                  Submit feedback
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Miscellaneous-->
      <div
        class="tab-content hidden p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full overflow-y-auto max-h-500"
        id="settings-misc"
      >
        <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-2">Miscellaneous</h3>
        <div class="flex py-4 px-3 items-center border-b">
          <p class="w-1/2 text-dark-purple-500 font-semibold">
            Default Page
            <span class="block text-sm font-medium text-dark-purple-400">Set the default landing page after login</span>
          </p>
          <div class="flex w-1/2 justify-end items-end ml-auto">
            <div class="flex items-center relative">
              <button
                id="dropdownProject"
                data-dropdown-toggle="page-dropdown"
                class="inline-flex items-center font-semibold text-center text-coral-600 hover:text-coral-700 hover:underline focus:outline-none dark:bg-gray-900 dark:hover:bg-gray-800 dark:text-white"
              >
                Chat<span class="mdi mdi-chevron-down text-lg text-dark-purple-500 ml-1"></span>
              </button>
              <div
                id="page-dropdown"
                class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-32 dark:bg-gray-700"
              >
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
                  <li>
                    <a
                      href="#"
                      class="block px-4 py-2 rounded-lg mx-2 hover:bg-dark-purple-50 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Chat</a
                    >
                  </li>
                  <li>
                    <a
                      href="#"
                      class="block px-4 py-2 rounded-lg mx-2 hover:bg-dark-purple-50 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Dashboard</a
                    >
                  </li>
                  <li>
                    <a
                      href="#"
                      class="block px-4 py-2 rounded-lg mx-2 hover:bg-dark-purple-50 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Bundles</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="flex py-4 px-3 items-center border-b">
          <p class="w-1/2 text-dark-purple-500 font-semibold">
            Data Export
            <span class="block text-sm font-medium text-dark-purple-400">Export your account details and chats.</span>
          </p>
          <div class="flex w-1/2 justify-end items-end ml-auto">
            <button
              type="button"
              class="text-white bg-dark-purple-500 hover:bg-dark-purple-700 transition-colors ease-in-out duration-150 focus:ring-3 focus:outline-none focus:ring-dark-purple-200 dark:focus:ring-dark-purple-800 font-medium rounded-full text-sm tracking-wide px-4 py-2 text-center"
            >
              <i class="mdi mdi-export me-1"></i>Export
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #languageSelector let-hide>
  <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
    <li>
      <a
        class="block px-4 py-2 rounded-lg mx-2 hover:bg-dark-purple-50 dark:hover:bg-gray-600 dark:hover:text-white"
        (click)="setLanguage(Language.IT); hide()"
      >
        Italiano
      </a>
    </li>
    <li>
      <a
        class="block px-4 py-2 rounded-lg mx-2 hover:bg-dark-purple-50 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
        (click)="setLanguage(Language.EN); hide()"
      >
        English
      </a>
    </li>
  </ul>
</ng-template>

import { Language } from "../../../../core/enums/language.enum";
import { Role } from "../../../../features/authentication/enums/role.enum";
import { DepartmentDetail, IUser, User } from "../../../../features/authentication/models/user.model";

export interface IOperator extends IUser {}

export class Operator extends User {
  protected constructor(
    public override id: string,
    public override email: string,
    public override role: Role,
    public override language: Language,
    public override loginSystem: string,
    public override tenant: string,
    public override verticals: string[],
    public override departments: string[],
    public override status: number,
    public override departmentDetails: DepartmentDetail[],
    public override firstName?: string,
    public override lastName?: string
  ) {
    super(
      id,
      email,
      role,
      language,
      loginSystem,
      tenant,
      verticals,
      departments,
      status,
      departmentDetails,
      firstName,
      lastName
    );
  }

  public static override Build(operator: IOperator): Operator {
    return new this(
      operator.id,
      operator.email,
      operator.role,
      operator.language,
      operator.loginSystem,
      operator.tenant,
      operator.verticals,
      operator.departments,
      operator.status,
      operator.departmentDetails,
      operator.firstName,
      operator.lastName
    );
  }
}

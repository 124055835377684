import { Routes } from '@angular/router';
import { ALL_ROLES, Role } from './features/authentication/enums/role.enum';
import { AuthGuard } from './features/authentication/guards/auth.guard';
import { RoleGuard } from './features/authentication/guards/role.guard';
import { DashboardLayoutComponent } from './shared/components/dashboard-layout/dashboard-layout.component';

export const routes: Routes = [
  { path: '', redirectTo: 'chat', pathMatch: 'full' },
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard, RoleGuard],
        data: {
          roles: [...ALL_ROLES],
        },
        loadChildren: () => import('./features/dashboard/dashboard.routes').then((lib) => lib.routes),
      },
      {
        path: 'chat',
        canActivate: [AuthGuard, RoleGuard],
        data: {
          roles: [...ALL_ROLES],
        },
        loadChildren: () => import('./features/chat/chat.routes').then((lib) => lib.routes),
      },
      {
        path: 'repositories',
        canActivate: [AuthGuard, RoleGuard],
        data: {
          roles: [Role.ADMIN, Role.OPERATOR],
        },
        loadChildren: () => import('./features/repository/repository.routes').then((lib) => lib.routes),
      },
      {
        path: 'operators',
        canActivate: [AuthGuard, RoleGuard],
        data: {
          roles: [Role.ADMIN],
        },
        loadChildren: () => import('./features/operator/operator.routes').then((lib) => lib.routes),
      },
      {
        path: 'agents',
        canActivate: [AuthGuard, RoleGuard],
        data: {
          roles: [Role.ADMIN, Role.OPERATOR, Role.PLATFORM_MANAGER],
        },
        loadChildren: () => import('./features/agent/agent.routes').then((lib) => lib.routes),
      },
      {
        path: 'tenants',
        canActivate: [AuthGuard, RoleGuard],
        data: {
          roles: [Role.PLATFORM_MANAGER],
        },
        loadChildren: () => import('./features/tenant/tenant.routes').then((lib) => lib.routes),
      },
      {
        path: 'licenses',
        canActivate: [AuthGuard, RoleGuard],
        data: {
          roles: [Role.ADMIN, Role.PLATFORM_MANAGER],
        },
        loadChildren: () => import('./features/license/license.routes').then((lib) => lib.routes),
      },
    ],
  },
  {
    path: '',
    loadChildren: () => import('./features/authentication/authentication.routes').then((lib) => lib.routes),
  },
  { path: '**', redirectTo: 'chat' },
];

import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';

import { provideEffects } from '@ngrx/effects';
import { DashboardEffects } from './+state/dashboard.effects';
import * as fromDashboard from './+state/dashboard.reducer';

export function provideDashboard(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(fromDashboard.DASHBOARD_FEATURE_KEY, fromDashboard.uiReducer),
    provideEffects([DashboardEffects]),
  ]);
}

import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { map, switchMap } from "rxjs";
import { Paginate } from "../../../models/pagination.model";
import { Audit } from "../models/audit.model";
import { AuditService } from "../service/audit.service";
import { loadAuditActions } from "./audit.actions";

@Injectable()
export class AuditEffects {
  private readonly actions$ = inject(Actions);
  private readonly auditService = inject(AuditService);

  loadAudits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAuditActions.loadAudits),
      switchMap(({ page }) =>
        this.auditService.getAudits(page).pipe(
          map((audits: Paginate<Audit>) =>
            loadAuditActions.loadAuditsSuccess({
              audits,
            })
          )
        )
      )
    )
  );

  loadAuditsByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAuditActions.loadAuditsByUserID),
      switchMap(({ page, pageSize, id, tenant }) =>
        this.auditService.getAuditsByUserId(page, pageSize, id, tenant).pipe(
          map((audits: Paginate<Audit>) =>
            loadAuditActions.loadAuditsByUserSuccess({
              audits,
            })
          )
        )
      )
    )
  );
}

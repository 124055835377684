import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';

import { provideEffects } from '@ngrx/effects';
import { AuditEffects } from './+state/audit.effects';
import * as fromAudit from './+state/audit.reducer';

export function provideAudit(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(fromAudit.AUDIT_FEATURE_KEY, fromAudit.auditReducer),
    provideEffects(AuditEffects),
  ]);
}

import { ApplicationConfig, isDevMode, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { provideDialogConfig } from '@ngneat/dialog';
import { popperVariation, provideTippyConfig, tooltipVariation, withContextMenuVariation } from '@ngneat/helipopper';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { routes } from './app.routes';
import { provideI18n } from './core/i18n/transloco.provider';
import { httpInterceptor } from './core/interceptor/http.interceptor';
import { RefreshTokenInterceptor } from './core/interceptor/refresh-token.interceptor';
import { loaderInterceptor } from './core/loader/loader.interceptor';
import { provideAudit } from './shared/data/data-access-audit/provider';
import { provideBundle } from './shared/data/data-access-bundle/provider';
import { provideChat } from './shared/data/data-access-chat/provider';
import { provideDashboard } from './shared/data/data-access-dashboard/provider';
import { provideOperator } from './shared/data/data-access-operator/provider';
import { providePlatformManager } from './shared/data/data-access-platform-manager/provider';
import { provideReport } from './shared/data/data-access-report/provider';
import { provideRepository } from './shared/data/data-access-repository/provider';
import { provideSummary } from './shared/data/data-access-summary/provider';
import { provideUI } from './shared/data/data-access-ui/provider';
import { provideLottie } from './shared/providers/lottie.provider';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptors([httpInterceptor, loaderInterceptor]), withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    provideI18n(),
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
        contextMenu: withContextMenuVariation(popperVariation),
        menu: {
          ...popperVariation,
          appendTo: 'parent',
          arrow: false,
          offset: [0, 0],
        },
      },
    }),
    provideDialogConfig({
      closeButton: false,
    }),
    provideStore(),
    provideEffects(),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    provideLottie(),
    provideUI(),
    provideChat(),
    provideBundle(),
    provideRepository(),
    provideReport(),
    provideOperator(),
    provideSummary(),
    provideAudit(),
    provideDashboard(),
    providePlatformManager(),
  ],
};

import { Component, signal, WritableSignal } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'app-viewport-warning',
  standalone: true,
  templateUrl: './viewport-warning.component.html',
  styleUrls: ['./viewport-warning.component.scss'],
  imports: [LottieComponent, TranslocoPipe],
})
export class ViewportWarningComponent {
  options: WritableSignal<AnimationOptions> = signal({
    path: '/assets/lottie/viewport.json',
  });
}

import { Component, ElementRef, inject, OnInit, signal, viewChild } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { DialogRef } from '@ngneat/dialog';
import { UiKitBaseComponent } from '../../../core/ui-kit/components/ui-kit-base/ui-kit-base.component';
import { ConfirmDialogService } from './confirm-dialog.service';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [TranslocoPipe],
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent extends UiKitBaseComponent implements OnInit {
  private readonly confirmDialogService = inject(ConfirmDialogService);
  private readonly ref = inject(DialogRef);

  openModalBtn = viewChild<ElementRef>('openModalBtn');

  message = signal<string>('');
  confirmButtonLabel = signal<string>('confirm-dialog.labels.confirm');
  cancelButtonLabel = signal<string>('confirm-dialog.labels.cancel');

  ngOnInit(): void {
    this.message.set(this.ref.data['message']);
    this.confirmButtonLabel.set(this.ref.data['confirmButtonLabel']);
    this.cancelButtonLabel.set(this.ref.data['cancelButtonLabel']);
  }

  setAction(action: boolean): void {
    this.confirmDialogService.setResult(action);
    this.close();
  }

  close(): void {
    this.ref.close();
  }
}

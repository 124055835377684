import { EnvironmentProviders, makeEnvironmentProviders } from "@angular/core";
import { provideState } from "@ngrx/store";

import { provideEffects } from "@ngrx/effects";
import * as fromPlatformManager from "./+state/platform-manager.reducer";
import { PlatformManagerEffects } from "./+state/platform-manager.effects";

export function providePlatformManager(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(fromPlatformManager.PLATFORM_MANAGER_FEATURE_KEY, fromPlatformManager.uiReducer),
    provideEffects([PlatformManagerEffects]),
  ]);
}
